<template>
<div>
   <div :id="'Modalprint_'+ item._id" :ref="'Modalprint_' + item._id">
            <h2 class="text-xl font-bold mb-3 tracking-wider uppercase">{{$t('Shop online')}}</h2>
              <!-- Profile tab -->
           <!-- <img style="width:20%;margin:10px;" alt="..." class="rounded-full align-middle border-none" :src="image"/> -->
            <div class="flex justify-between">
                  <!-- <div class="w-full md:w-1/3">
                      <label class="text-black-700 block mb-1 text-xs uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">Expéditeur:</span> <span style="font-size: 0.65rem;line-height: 1.20rem;" class="text-black-800 mb-1 text-xs uppercase tracking-wide  font-bold"> santiago</span></label>
                  </div>  -->
                  <!-- <div class="w-full md:w-1/3">
                      <label class="text-black-700 block mb-1 text-xs uppercase tracking-wide" 
                      style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">
                      {{$t('phone')}}:</span> <span style="font-size: 0.65rem;line-height: 1.20rem;" 
                      class="text-black-800 mb-1 text-xs uppercase tracking-wide font-bold">{{item.phone}} 
                      </span></label>
                  </div>  -->
                  <!-- <div class="w-full md:w-1/3">
                      <label class="text-black-700 block text-sm uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">{{$t('order_no')}}:</span> <span style="font-size: 0.65rem;line-height: 1.20rem;" class="text-black-800 text-xs uppercase tracking-wide font-bold">{{item.order.id}} </span></label>
                  </div>  -->
            </div>   
            <div class="flex justify-between">
                  <div class="w-full md:w-1/3">
                   <label class="text-black-700 block text-sm uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">{{$t('order_no')}}:</span> <span class="text-black-800 text-xs uppercase tracking-wide font-bold" style="font-size: 0.65rem;line-height: 1.20rem;">{{item.order.id}} </span></label>
                  </div> 
                  <div class="w-full md:w-1/3">
                      <label class="text-black-700 block text-sm uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">{{$t('order_date')}}:</span> <span class="text-black-800 text-xs uppercase tracking-wide font-bold" style="font-size: 0.65rem;line-height: 1.20rem;">{{$moment(item.date).format('yyyy-MM-DD')}} </span></label>
                  </div>   
            </div>   
            <div class="flex justify-between">
                  <div class="w-full md:w-1/3">
                   <label class="text-black-700 block text-sm uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">{{$t('phone')}}:</span> <span class="text-black-800 text-xs uppercase tracking-wide font-bold" style="font-size: 0.65rem;line-height: 1.20rem;">{{item.order.customer.phone}} </span></label>
                  </div> 
                  <div class="w-full md:w-1/3">
                      <label class="text-black-700 block text-sm uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">{{$t('store')}}:</span> <span class="text-black-800 text-xs uppercase tracking-wide font-bold" style="font-size: 0.65rem;line-height: 1.20rem;">{{item.seller.company}} </span></label>
                  </div>   
            </div>   
                 <div class="flex justify-between">
                <div class="w-full md:w-1/3">
                      <label class="text-black-700 block text-sm uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">{{$t('name')}}:</span> <span style="font-size: 0.65rem;line-height: 1.20rem;" class="text-black-800 text-xs uppercase tracking-wide font-bold">{{item.order.customer.fullName}} </span></label>
                  </div> 
                    <div class="w-full md:w-1/3">
                       <label class="text-black-700 block text-sm uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">{{$t('order_no')}}:</span> <span style="font-size: 0.65rem;line-height: 1.20rem;" class="text-black-800 text-xs uppercase tracking-wide font-bold">{{item.order.id}} </span></label>
                  </div> 
              </div>
               <div class="flex justify-between">
                  <!-- <div class="w-full md:w-1/3">
                   <label class="text-black-700 block text-sm uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">{{$t('city')}}:</span> <span class="text-black-800 text-xs uppercase tracking-wide font-bold" style="font-size: 0.65rem;line-height: 1.20rem;">{{item.order.customer.city}} </span></label>
                  </div>  -->
                   
              </div>

             <div class="flex justify-between">
                <div class="w-full">
                <label class="text-black-700 block text-xs uppercase tracking-wide" 
                style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">
                {{$t('shipping_address')}}:</span> <span style="font-size: 0.65rem;line-height: 1.20rem;" 
                class="text-black-800 text-xs uppercase tracking-wide font-bold">{{item.address}}
                 </span></label>
                </div>
              </div>
    
          <!--<p class="size-m leading-5 font-bold text-black">
              {{$t('order_items')}} :
          </p>-->
                       
          <table class="min-w-full">
              <thead class="justify-between" >
              <tr class="border border-black" >
                  <th  class="px-2 text-left leading-4 text-black tracking-wider">
                  <span class="text-black">#</span>
                  </th>
                  <th style="font-size: 0.65rem;line-height: 1.20rem;"  class="px-2 text-left text-xs leading-4 text-black tracking-wider">
                  <span class="text-black">{{$t('item')}}</span>
                  </th>
                  <th style="font-size: 0.65rem;line-height: 1.20rem;" class="px-2 text-center text-xs leading-4 text-black tracking-wider">
                  <span class="text-black">{{$t('quantity')}}</span>
                  </th>
                  

                  <th style="font-size: 0.65rem;line-height: 1.20rem;" class="px-2 text-center text-xs leading-4 text-black tracking-wider">
                  <span class="text-black">{{$t('amount')}}</span>
                  </th>
              </tr>
              </thead>
              <tbody class="bg-white w-full">
              <tr v-for="(el, index) in item.order.details" :key="index"  class="bg-white border border-black">
                  <td class="px-2 whitespace-no-wrap">
                    <span style="font-size: 0.65rem;line-height: 1.20rem;" class="px-2 text-xs whitespace-no-wrap text-black">{{index+1}}</span>
                  </td>
                  <td class="" >
                  <span style="font-size: 0.65rem;line-height: 1.20rem;white-space: initial;" class="px-2 text-xs  whitespace-no-wrap text-black font-bold">{{el.product.name}}</span>
                  </td>
                  <td class="px-2 text-center whitespace-no-wrap text-black">
                  <span class="size-m leading-5 font-bold">{{el.quantity}}</span>
                  </td>
                  <td class="px-2 text-center whitespace-no-wrap text-black">
                  <span class="size-m leading-5 font-bold">{{ (el.unitPrice*el.quantity).toFixed(2)}}</span>
                  </td>
              </tr>
              </tbody>
          </table>
           <div class="ml-auto w-full sm:w-2/4 lg:w-1/6" style="border-top:1px solid #000;margin-top:4px">
              <div class="flex justify-between">
                  <div class="text-black text-right size-m flex-1 text-xs font-bold" style="font-size: 0.75rem;">{{$t('total')}}: </div>
                  <div class="text-right" style="width: 40mm; margin-right:5px">
                      <div class="text-black text-xs font-bold" style="font-size: 0.75rem;"> {{(item.order.totalPrice).toFixed(2)}} <sup v-if="idWharhouse">{{idWharhouse.currency}}</sup></div>
                  </div>
              </div>
          </div>
           <div class="flex justify-center">
               <p><span class="py-4 whitespace-no-wrap border-b text-xs leading-5" style="font-size: 0.75rem;"><span v-if="item.autorise_colis">{{$t('autorise_colis')}}</span><span v-else>{{$t('not_autorise_colis')}}</span></span></p>        
          </div> 
          <div class="text-center barcode-img delivrey">
              <vue-barcode  :value="item.trackingNumber" tag="svg"></vue-barcode>
            <!--<p class="text-black-600"><img width="200" class="inline" :src="image" alt=""></p>-->
          </div>
          <div class="flex w-full received" style="padding: 5px;">
              <!-- <span class="px-5 py-2 w-full border-black border text-black-500 rounded">RECEIVED BY:
                  <p><span class=" py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500text-xs leading-5">Name and signature:</span></p>
              </span>              -->
          </div>
      </div> 

      <!--shipp list livreur -->
       <div :id="'ModalprintList_'+ item._id" class="shipp-print">     
          <!-- Profile tab -->      
              <tr  class="bg-white border border-black">
                  <td style="padding-left:3px;padding-right:3px" class="text-center whitespace-no-wrap text-black">
                      <span style="font-size: 0.50rem;line-height: 0.70rem;" class="text-xs text-black">{{item.order.id}}</span>
                  </td>
                  <td style="padding-left:3px;padding-right:3px" class="text-center whitespace-no-wrap text-black">
                    <span style="font-size: 0.50rem;line-height: 0.70rem;" class="text-xs overflow-visible whitespace-no-wrap text-black">{{item.trackingNumber}}</span>
                  </td>
                  <td style="padding-left:3px;padding-right:3px" class="text-center whitespace-no-wrap text-black">
                  <span style="font-size: 0.50rem;line-height: 0.70rem;" class="text-xs text-black">{{item.order.customer.city}}</span>
                  </td>
                  <td style="padding-left:3px;padding-right:3px" class="text-center whitespace-no-wrap text-black">
                    <span style="font-size: 0.50rem;line-height: 0.70rem;" class="text-xs text-black">{{item.seller.fullName||item.seller.company}}</span>
                  </td>
                  <td style="padding-left:3px;padding-right:3px" class="text-center whitespace-no-wrap text-black">
                    <span style="font-size: 0.50rem;line-height: 0.70rem;" class="text-xs text-black">{{QuantityTotalProductOrder(item)}}</span>
                  </td>
                  <td style="padding-left:3px;padding-right:3px" class="text-center whitespace-no-wrap text-black">
                   <span style="font-size: 0.50rem;line-height: 0.70rem;" class="text-xs text-black">{{ (item.order.totalPrice).toFixed(2)}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></span>
                  </td>
                  <td style="padding-left:3px;padding-right:3px" class="barcode-img text-center whitespace-no-wrap text-black">
                     <vue-barcode  :value="item.trackingNumber" tag="img"></vue-barcode>
                  </td>
              </tr> 
      </div>
      <!--/shipp list courier --> 
</div>
</template>
<script>
import css from "@/assets/style.css";
import trbodytable from '@/views/shippings/tableProducts.vue' 
import image from "@/assets/edco_logo.png";
export default {
   components: {
      trbodytable,
    },
    data() {
    return { 
      dropdownPopoverShow: false,
      cssLink: css,
      image:"",
      usernameTeleconsul:'',
      expediteur:'',
    };
    },
    props: {
        config: { required: true, type: Object},
        item:{ default: [] },      
        idWharhouse:{ type: Object },      
    },
    computed: {
      formattedDate() {
        return this.$moment(this.item.date).format('yyyy-MM-DD HH:mm');
      }, 
    },
    async mounted() {

    },
    methods: {
    exceptNmaeProduct(text){
         if (text && text.length > 60) text = text.substring(0, 60) + "...";
          return text;
      },
      QuantityTotalProductOrder(data){
        var qte=0;
        for (let i in data.order.details) {
            qte+=data.order.details[i].quantity;
        }
        return qte;
    },
     async getStatusByName(name) {
        const result = await this.$server.get("status",{ name: name });
        return result.content._id;
     },
    }    
}
</script>
